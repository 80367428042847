<template>
    <div>
        <base-header base-title="Skema Rujukan"></base-header>
        <div class="kt-content kt-grid__item kt-grid__item--fluid" id="kt_content">
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <auto-refresh :table-name="tableName" :on-refresh="fetch"></auto-refresh>&nbsp;&nbsp;
                            <template v-if="checkPermission('SkemaRujukanResource.POST.SkemaRujukan')">
                            <base-button
                                :button-text="modalText"
                                :button-icon="modalIcon"
                                :button-name="modalName"
                                :onButtonModalClicked="openForm"
                            ></base-button>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body" v-if="isLoaded">
                    <data-tables :table="table"></data-tables>
                </div>
                <div v-else class="m-auto p-5 d-flex flex-row h-100">
                    <div class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"></div>
                    <h5 class="my-auto pl-5">Loading ...</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CheckPermission from "./../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import BaseTitle from "./../_base/BaseTitle";
import BaseHeader from "./../_base/BaseHeader";
import AutoRefresh from "./../_general/AutoRefresh";
import BaseButton from "./../_base/BaseButton";
import DataTables from "./../_table/DataTables";
import BtnAction from "./_components/BtnAction";
import LoadSpinner from "./../_general/LoadSpinner";
import SkemaRujukan from "./../../model/skema-rujukan-model";
import { RepositoryFactory } from "./../../repositories/RepositoryFactory";
const SkemaRujukanRepository = RepositoryFactory.get("skemaRujukan");
export default {
    components: {
        BaseHeader,
        BaseTitle,
        AutoRefresh,
        BaseButton,
        DataTables,
        LoadSpinner,
        BtnAction
    },
    data() {
        var vx = this;
        return {
            isLoaded: false,
            isShowModal: false,
            dataEdit: null,
            headTitle: "Skema Rujukan",
            tableName: "table_skema",
            modalText: "Tambah Skema Rujukan",
            modalName: "modal_skema_rujukan",
            modalIcon: "flaticon2-add-1",
                table: {
                dateFormat: "dd-M-yyyy",
                tableName: "table_skema",
                tableDataResult: [],
                tableColumnDefs: [
                    {
                        defaultContent: "-",
                        targets: "_all"
                    },
                    {
                        targets: [2,3],
                        render: function ( data, type, row, meta ) {
                            var momentDate = moment(data);
                            return(momentDate.format("DD-MMM-YYYY"));
                        }
                    },
                    {
                        targets: -1,
                        responsivePriority: 1
                    }
                ],
                tableColumns: [
                {
                    title: "ID",
                    data: "id"
                },
                {
                    title: "Nama",
                    data: "nama"
                },
                {
                    title: "Tanggal Mulai",
                    data: "tanggalMulai"
                },
                {
                    title: "Tanggal Selesai",
                    data: "tanggalSelesai"
                },
                {
                    title: "Actions",
                    data: "id",
                    createdCell: function(cell, cellData, rowData, rowIndex, colIndex) {
                        let btnAction = Vue.extend(BtnAction);
                        let instance = new btnAction({
                            propsData: {
                                skemaVue: vx,
                                rowData: rowData
                            }
                    });
                    instance.$mount();
                    $(cell)
                        .empty()
                        .append(instance.$el);
                    }
                }
                ],
                tableFilter: [
                    {
                        title: "Nama",
                        type: "text"
                    },
                    {
                        title: "Tanggal Mulai",
                        type: "datepicker"
                    },
                    {
                        title: "Tanggal Selesai",
                        type: "datepicker"
                    },
                    {
                        title: "Actions",
                        type: "actions"
                    }
                ]
            }
        }
    },
    watch: {
        isShowModal: function(val) {
            if(!val) {
                this.dataEdit = null;
            }
        }
    },
    methods: {
        openView: function(data){
            this.$router.push({ name: 'skema-rujukan-view', params: { id: data.id } })
        },
        openForm: function(data = new SkemaRujukan() ) {
            if(data.id) {
                this.$router.push({ name: 'skema-rujukan-edit', params: { id: data.id } })
            } else {
                this.$router.push({ name: 'skema-rujukan-add'})
            }
        },
        async fetch(resolve) {
            new Promise((resolve, reject) => {
                SkemaRujukanRepository.get({}, resolve, reject );
            }).then(response => {
                const data = response.data;
                if (this.isLoaded) {
                    var datatable = $("#" + this.tableName).DataTable();
                    datatable.clear();
                    datatable.rows.add(data);
                    datatable.draw("full-hold");
                    if(resolve !== undefined) {
                        resolve(data);
                    }
                } else {
                    this.table.tableDataResult = data;
                    this.isLoaded = true;
                }
            }).catch(error => {
                var msg = (error.message) ? error.message : error;
                this.showToastError('Skema Rujukan : ' + msg);
            })

        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        },
        showToastError: function(message) {
            toastr.error(message);
        }
    },
    created() {
         this.fetch();
    }
}
</script>

<style>

</style>
