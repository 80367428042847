export default function SkemaRujukan() {
    return {
        id: null,
        isDeleted: false,
        jenisPenjamins: [],
        kategoris: [],
        nama: null,
        kelasIdOrdered: null,
        parentTindakans: [],
        tanggalMulai: null,
        tanggalSelesai: null
    };
}
