<template>
    <div class="text-center">
         <template v-if="checkPermission('SkemaRujukanResource.GET.SkemaRujukan.id')">
             <button
                    @click="viewSkema()"
                    class="btn btn-sm btn-clean btn-icon btn-icon-md"
                    title="Edit"
                >
            <i class="la la-eye"></i>
            </button>
        </template>
        <template v-if="checkPermission('SkemaRujukanResource.PUT.SkemaRujukan')">
        <button
            @click="editSkema()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Edit"
        >
            <i class="la la-pencil-square"></i>
        </button>
        </template>
        <template v-if="checkPermission('SkemaRujukanResource.DELETE.SkemaRujukan.id')">
        <button
            @click="deleteSkema()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Hapus"
        >
            <i class="la la-trash"></i>
        </button>
        </template>
    </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const SkemaRujukanRepository = RepositoryFactory.get("skemaRujukan");
const checkPermission = new CheckPermission();
const SweetAlert = Vue.extend(SweetAlertTemplate);
export default {
    props: {
        skemaVue: { type: Object },
        rowData: { type: Object }
    },
    methods: {
        deleteSkema() {
            var vx = this;
            let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Data Skema Rujukan akan terhapus.",
                swalType: "warning",
                onConfirmButton: function () {
                    blockUI.blockPage();
                    vx.delete();
                },
                onCancelButton: function () {
                    blockUI.unblockPage();
                }
            });
        },
        async delete() {
            var vx = this;

            await SkemaRujukanRepository.deleteSkema(this.rowData.id)
                .then(response => {
                    new Promise((resolve, reject) => {
                        vx.skemaVue.fetch(resolve);
                    }).then((response) => {
                        let instance = new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: "Data Skema Rujukan berhasil terhapus.",
                        swalType: "success",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: "Data Skema Rujukan gagal terhapus.",
                        swalType: "error",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                    });
                });
            },
        editSkema() {
            this.skemaVue.openForm(this.rowData);
        },
        viewSkema() {
            this.skemaVue.openView(this.rowData);
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        }
    }
}
</script>

<style>

</style>
