<template>
  <button
    type="button"
    class="btn btn-default btn-bold btn-upper btn-font-sm mr-1 ml-1"
    @click="onButtonClicked()"
  >
    <i :class="buttonIcon"></i>
    {{ buttonText }}
  </button>
</template>

<script>
export default {
  props: {
    buttonText: { type: String },
    buttonName: { type: String },
    buttonIcon: { type: String },
    onButtonModalClicked: { type: Function }
  },
  methods: {
    onButtonClicked: function() {
      if (this.onButtonModalClicked != null) {
        this.onButtonModalClicked();
      }
    }
  }
};
</script>
